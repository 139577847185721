<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import { authHeader } from "../../../helpers/auth-header";

import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";

import InputGroup from "@/components/newforms/input-group";
import SelectGroup from "@/components/newforms/select-group";

import SaveButton from "@/components/buttons/save-button";
import LoadingSpinner from '@/components/Loading';
import ImageFile from '@/components/buttons/image-file';

/**
 * Orders Component
 */
export default {
    components: {
        Layout,
        PageHeader,
        ConfirmationModal,
        MessageModal,
        InputGroup,
        SelectGroup,
        SaveButton,
        LoadingSpinner,
        ImageFile
    },
    data() {
        const id = this.$route.params.id;
        const modeName = id === 'add' ? 'Add' : 'Edit';

        return {
            id: id,
            title: `Agent ${modeName}`,
            modeName,
            items: [
                {
                    text: "Agent"
                },
                {
                    text: modeName,
                    active: true,
                },
            ],
            data: {
                registration_number: '',
                agency_id: '',
                member_id: '',
            },
            file: [],
            agency_proof: [],
            agent_proof: [],
            errors: {},
            withAgencyOptions: [
                { name: 'With Agency', value: 1 },
                { name: 'Free Agent', value: 0 },
            ],
            agency_options: [],
            member_options: [],
            redirect_page: false,
            button_click: false,
            isLoading: false,
        };
    },
    computed: {

    },
    async mounted() {
        this.isLoading = true;
        await this.loadAgencyOptions();
        await this.loadMemberOptions();
        if (this.modeName !== 'Add') {
            const result = await axios.get(`${process.env.VUE_APP_APIURL}/agent/${this.id}`, {
                headers: authHeader(),
            });
            if (result && result.data['code'] === 'success') {
                const data = result.data['data'];
                console.log('data', data)
                for (const [key, item] of Object.entries(data)) {
                    if (key in this.data) {
                        this.data[key] = item;
                    }
                }

                this.agent_proof = data.agent_pic;
            }
        }
        this.isLoading = false;
    },
    methods: {
        handleFileChange() {
             // Get the file from the event
            // const files = event.target.files;
            // if (files.length > 0) {
            //     this.file.push(files);
            // } else {
            //     this.file = null;
            // }
        },
        async save() {
            if (this.button_click) return ;
            this.button_click = true;
            const formData = new FormData();
            if (this.agency_proof.length > 2) {
                for (let i = 0; i < this.agency_proof.length; i++ ){
                    formData.append(`agency_proof[${i}]`, this.agency_proof[i]);
                }
            } else {
                formData.append("agency_proof[0]", this.agency_proof[0]);
            }
            this.errors = {};
            let result;
            Object.keys(this.data).forEach(e => {
                formData.append(e, this.data[e]);
            });

            if (this.modeName === 'Add') {
                // this.requestData
                result = await axios.post(`${process.env.VUE_APP_APIURL}/agent`, formData, {
                    headers : {
                    'Content-Type' : 'image/png',
                    'Authorization': authHeader()["Authorization"]
                    }
                });
            } else {
                const requestData = this.data;
                console.log('requestData', requestData)
                result = await axios.put(`${process.env.VUE_APP_APIURL}/agent/${this.id}`, requestData, {
                    headers : {
                    'Content-Type' : 'image/png',
                    'Authorization': authHeader()["Authorization"]
                    }
                });
            }

            if (result) {
                this.button_click = false;
                if (result.data['code'] === 'success') {
                    if (this.modeName === 'Add') {
                        this.id = result.data.data.id;
                        // this.redirect_page = true;

                        // this.$refs.messageModal.showModal('Your record has been added successfully');
                        this.$router.push({ 'name': "Agent" });
                        // this.pageRedirection = true;
                    } else {
                        this.$refs.messageModal.showModal('Your record has been updated successfully');
                        // this.pageRedirection = true;
                    }
                } else if (result.data['code'] === 'permission_denied') {
                    this.$refs.messageModal.showModal('Permission Denied');
                } else if (result.data['code'] === 'invalid_field') {
                    this.errors = result.data['errors'];
                } else if (result.data['code'] === 'invalid_member') {
                    this.errors = result.data['errors'];
                }
            }
        },

        async loadAgencyOptions() {
            await axios.get(`${process.env.VUE_APP_APIURL}/agency-options`, {
                headers: authHeader(),
            }).then(e => {
                if (e.status == 200) {
                    const { data } = e.data;
                    this.agency_options = data;
                }
            });
        },
        async loadMemberOptions() {
            await axios.get(`${process.env.VUE_APP_APIURL}/member-list`, {
                headers: authHeader(),
            }).then(e => {
                if (e.status == 200) {
                    const { data } = e.data;
                    const val = data.map(e => { return {"text": e.full_name, "value": e.id} });
                    this.member_options = val;
                }
            });
        },
        redirect() {
            if (this.redirect_page)
                this.$router.push({ 'name': "Agent View", params: {id: this.id} });
        }
    }
};
</script>

<template>
    <Layout>
        <LoadingSpinner :isLoading="isLoading" />
        <PageHeader :title="title" :items="items" />
        <ConfirmationModal ref="confirmationModal" />
        <MessageModal ref="messageModal" @CloseBtn="redirect" />
        <div class="card" style="border-radius: 15px;">
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-md-4 mb-2">
                        <SelectGroup id="member_id" name="member_id" v-model="data.member_id" label="Member" placeholder="Select a member" :options="member_options" :error="errors.member_id"/>
                    </div>

                    <div class="col-12 col-md-4 mb-2">
                        <InputGroup id="registration_number" name="registration_number" v-model="data.registration_number" label="Registration Number" placeholder="Registration Number" :error="errors.registration_number"/>
                    </div>
                    <div class="col-12 col-md-4 mb-2">
                        <SelectGroup id="agency_id" name="agency_id" v-model="data.agency_id" label="Agency (Optional)" placeholder="Select An Agency" :options="agency_options" :error="errors.agency_id"/>
                    </div>
                    <!-- <div class="col-12 col-md-4 mb-2">
                        <SelectGroup id="member_id" name="member_id" v-model="data.member_id" label="Member" placeholder="Select An Agency" :options="member_options" :error="errors.member_id"/>
                    </div> -->
                </div>
                <div class="row">
                    <div class="col-12 col-md-4 mb-2 mt-2">
                        <label class="mb-0">Upload Proof of Registration</label>
                        <b-form-file v-model="agency_proof" multiple accept="image/*" @change="handleFileChange"></b-form-file>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-sm-6 col-md-4 mt-2" v-for="doc, index in agent_proof" :key="doc.id">
                        <ImageFile :img_url="doc.image" :file_name="'proof_doc-' + (parseInt(index) + 1)" :file_id="parseInt(doc.id)" />
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 mt-3">
                        <SaveButton @click="save" />
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>